<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <!-- <notifications group="appNotify"/> -->

      <notifications group="appNotify" position="bottom center" width="400px">
   <!-- <template slot="body" slot-scope="props">
    <div>
        <a class="title">
          {{props.item.title}}
        </a>
        <a class="close" @click="props.close">
          <i class="fa fa-fw fa-close"></i>
        </a>
        <div v-html="props.item.text">
        </div>
    </div>
  </template> -->
</notifications>
      <router-view />
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {
    
  }

};
</script>

<style>

/* .text-right, .text-right > span{
  font-size: .7rem !important;
}
.text-start, .text-start > span{
  font-size: .7rem !important;
}
.text-end, .text-end > span{
  font-size: .7rem !important;
}

.text-center, .text-center > span{
  font-size: .7rem !important;
} */
.tableValue td{
  font-size: .7rem !important;
  /* color:red; */
}

</style>